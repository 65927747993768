import React from "react";

const Button = ({ label, disabled, classes, onClick }) => {
  return (
    <button
      className={`btn ${classes}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
