import { Routes, Route } from "react-router-dom";

import "./styles/App.css";
import Header from "./components/Header";
import Home from "./page/Home";
import Result from "./page/Result";

function App() {
  return (
    <div className="container">
      <Header />
      <div className="d-flex flex-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/result" element={<Result />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
