import logo from "../images/logo.png";

function Header() {
  return (
    <header className="header-wrap">
      <img src={logo} className="logo" alt="logo" />
      <h1>MorocNest</h1>
    </header>
  );
}

export default Header;
