import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ResultItem from "../components/ResultItem";
import ResultSearch from "../components/ResultSearch";

export const apiDomain = "http://morocnest.co.uk/api";

const fakeItemData = [
  {
    property_id: "p1894",
    city: "Rabat",
    purpose_of_renting: "Accommodation",
    property_type: "Apartment",
    number_of_rooms: 2,
    sub_features: ["Duplex", "No First Floor"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Concierge",
      "Underground Parking",
    ],
  },
  {
    property_id: "p3453",
    city: "Marrakesh",
    purpose_of_renting: "Accommodation",
    property_type: "House",
    number_of_rooms: 0,
    sub_features: ["Commercial", "Garage"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Underground Parking",
    ],
  },
  {
    property_id: "p3858",
    city: "Marrakesh",
    purpose_of_renting: "Holiday",
    property_type: "House",
    number_of_rooms: 0,
    sub_features: ["Commercial"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Underground Parking",
    ],
  },
  {
    property_id: "p4229",
    city: "Rabat",
    purpose_of_renting: "Accommodation",
    property_type: "Apartment",
    number_of_rooms: 1,
    sub_features: ["No First Floor"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Concierge",
      "Underground Parking",
    ],
  },
  {
    property_id: "p5179",
    city: "Casablanca",
    purpose_of_renting: "Accommodation",
    property_type: "Villa",
    number_of_rooms: 1,
    sub_features: [],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Underground Parking",
    ],
  },
  {
    property_id: "p5530",
    city: "Rabat",
    purpose_of_renting: "Accommodation",
    property_type: "Apartment",
    number_of_rooms: 2,
    sub_features: ["No First Floor"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Concierge",
      "Underground Parking",
    ],
  },
  {
    property_id: "p7353",
    city: "Rabat",
    purpose_of_renting: "Accommodation",
    property_type: "Apartment",
    number_of_rooms: 1,
    sub_features: ["No First Floor"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Concierge",
      "Underground Parking",
    ],
  },
  {
    property_id: "p495",
    city: "Casablanca",
    purpose_of_renting: "Holiday",
    property_type: "Apartment",
    number_of_rooms: 2,
    sub_features: ["No First Floor"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Underground Parking",
    ],
  },
  {
    property_id: "p585",
    city: "Casablanca",
    purpose_of_renting: "Holiday",
    property_type: "Villa",
    number_of_rooms: 0,
    sub_features: ["Duplex", "Garage"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Underground Parking",
    ],
  },
  {
    property_id: "p682",
    city: "Marrakesh",
    purpose_of_renting: "Accommodation",
    property_type: "Apartment",
    number_of_rooms: 1,
    sub_features: ["No First Floor"],
    features: [
      "Air Conditioning",
      "Heating",
      "Furnished",
      "Double Glazing",
      "Elevator",
      "Security System",
      "Concierge",
      "Underground Parking",
    ],
  },
]; // del

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Result() {
  const query = useQuery();
  const user = query.get("user");

  const [feedbackData, setFeedbackData] = useState([]);
  const [properties, setProperties] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchRefresh = async (apiData) => {
    setError(null);
    setLoading(true);
    try {
      const response = await fetch(`${apiDomain}/refresh/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiData),
      });

      if (!response.ok) {
        throw new Error("Network API refresh response was not ok");
      }

      const data = await response.json();
      console.log("data: ", data);
      setProperties(data);
    } catch (error) {
      console.warn("error API refresh: ", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchClicked = (preferenceData) => {
    const apiData = {
      user: user,
      preferences: preferenceData,
      feedback: feedbackData,
    };
    console.log("apiData: ", apiData);
    fetchRefresh(apiData);
  };

  const handleFeedbackTypeClicked = (property_id, feedbackType) => {
    setFeedbackData((prevFeedback) => {
      const existingFeedbackIndex = prevFeedback.findIndex(
        (item) => item.property_id === property_id
      );

      if (existingFeedbackIndex !== -1) {
        const existingFeedback = prevFeedback[existingFeedbackIndex];
        if (existingFeedback.feedbackType === feedbackType) {
          return prevFeedback.filter(
            (_, index) => index !== existingFeedbackIndex
          );
        } else {
          return prevFeedback.map((item, index) =>
            index === existingFeedbackIndex ? { ...item, feedbackType } : item
          );
        }
      } else {
        return [...prevFeedback, { property_id, feedbackType }];
      }
    });
  };

  const findActive = (id) => {
    const foundFeedback = feedbackData.find((item) => item.property_id === id);

    if (foundFeedback) {
      return foundFeedback.feedbackType;
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      setError(null);
      setLoading(true);
      try {
        const response = await fetch(
          `${apiDomain}/find-properties/?user=${encodeURIComponent(user)}`
        );

        if (!response.ok) {
          throw new Error("Network API find-properties response was not ok");
        }

        const data = await response.json();
        console.log("data: ", data);
        setProperties(data);
      } catch (error) {
        console.warn("error API find-properties: ", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [user]);

  return (
    <div className="result-wrap">
      <div className="search-group">
        <ResultSearch onSearchClicked={handleSearchClicked} />
      </div>
      {loading && <p className="text-center">Loading...</p>}
      {error && <p className="text-center">Error: {error}</p>}
      {!loading && !error && (
        <div className="search-result">
          {properties &&
            properties.map((item) => (
              <ResultItem
                key={item.property_id}
                item={item}
                active={findActive(item.property_id)}
                onFeedbackTypeClicked={(feedbackType) =>
                  handleFeedbackTypeClicked(item.property_id, feedbackType)
                }
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default Result;
