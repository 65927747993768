import React from "react";
import dislike from "../images/dislike.png";
import like from "../images/like.png";

const ResultItem = ({ item, active = false, onFeedbackTypeClicked }) => {
  const {
    property_id,
    price = "--",
    city,
    purpose_of_renting,
    property_type,
    number_of_rooms,
    sub_features,
    features,
  } = item;

  return (
    <div className="result-item">
      <div className="room-info">
        {/* <p>{property_id}</p> */}
        <p>{`${price.toLocaleString()} DH`}</p>
        <p className="p-20">
          {city} | {purpose_of_renting}
          <br />
          {`${property_type} | ${number_of_rooms} bed`}
          {`${sub_features.length && " | "}`}
          {`${sub_features.join(" | ")}`}
          <br />
          {features.slice(0, 3).join(" | ")}
          <br />
          {features.slice(3).join(" | ")}
        </p>
      </div>
      <img
        src={dislike}
        className="icon"
        alt="dislike"
        onClick={() => onFeedbackTypeClicked("dislike")}
        style={{
          backgroundColor: `${active === "dislike" ? "red" : "transparent"}`,
        }}
      />
      <img
        src={like}
        className="icon"
        alt="like"
        onClick={() => onFeedbackTypeClicked("like")}
        style={{
          backgroundColor: `${active === "like" ? "red" : "transparent"}`,
        }}
      />
    </div>
  );
};

export default ResultItem;
