import React, { useState } from "react";

const Dropdown = ({
  defaultName,
  options,
  classes,
  onSelect,
  hiddenDefaultName = true,
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChanged = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div className="select-wrapper">
      <select
        className={classes}
        value={selectedOption}
        onChange={(e) => handleSelectChanged(e.target.value)}
      >
        <option
          value=""
          disabled={hiddenDefaultName}
          hidden={hiddenDefaultName}
        >
          {defaultName}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
