import React, { useState } from "react";

import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import MultiSelect from "../components/MultiSelect";
import { apiDomain } from "../page/Result";

const cityOptions = [
  { value: "Rabat", label: "Rabat" },
  { value: "Casablanca", label: "Casablanca" },
  { value: "Marrakesh", label: "Marrakesh" },
];

const purposeOptions = [
  { value: "Accommodation", label: "Accommodation" },
  { value: "Holiday", label: "Holiday" },
];

const typeOptions = [
  { value: "Apartment", label: "Apartment" },
  { value: "House", label: "House" },
  { value: "Villa", label: "Villa" },
  { value: "Riad", label: "Riad" },
];

const priceOptions = [
  { value: "2,000-4,000", label: "2,000 ~ 4,000" },
  { value: "4,000-8,000", label: "4,000 ~ 8,000" },
  { value: "8,000-12,000", label: "8,000 ~ 12,000" },
  { value: "12,000-16,000", label: "12,000 ~ 16,000" },
];

const roomsOptions = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

const fakeFeaturesOptions = [
  "Air conditioning",
  "Heating",
  "Furnished",
  "Double glazing",
  "Elevator",
  "Balcony",
  "Terrace",
  "Swimming pool",
  "Garden",
  "Security system",
  "Concierge",
  "Parking",
  "Underground Parking",
]; // del

const fakeSubfeaturesOptions = [
  "Duplex",
  "No first floor",
  "Commercial",
  "Garage",
]; // del

const ResultSearch = ({ onSearchClicked }) => {
  const [preferencesData, setPreferencesData] = useState({});

  const [featuresOptions, setFeaturesOptions] = useState();
  const [subfeaturesOptions, setSubFeaturesOptions] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDropdownChanged = (key, value) => {
    setPreferencesData((prevPreferences) => {
      const newPreferences = { ...prevPreferences };
      if (value === "" || value.length === 0) {
        delete newPreferences[key];
      } else {
        newPreferences[key] = value;
      }
      return newPreferences;
    });
  };

  const fetchFeatures = async (property_type) => {
    setError(null);
    if (!property_type) {
      setFeaturesOptions(null);
      setSubFeaturesOptions(null);
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${apiDomain}/property-features/?property_type=${encodeURIComponent(
          property_type
        )}`
      );

      if (!response.ok) {
        throw new Error("Network API property-features response was not ok");
      }

      const data = await response.json();
      console.log("data: ", data);
      setFeaturesOptions(data.features);
      setSubFeaturesOptions(data.sub_features);
    } catch (error) {
      console.warn("error API property-features: ", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex-column">
        <div className="multi-select">
          <Dropdown
            classes={"result-select"}
            defaultName={"City"}
            options={cityOptions}
            hiddenDefaultName={false}
            onSelect={(e) => handleDropdownChanged("city", e)}
          />
          <Dropdown
            classes={"result-select"}
            defaultName={"Renting Purpose"}
            options={purposeOptions}
            hiddenDefaultName={false}
            onSelect={(e) => handleDropdownChanged("purpose_of_renting", e)}
          />
          <Dropdown
            classes={"result-select"}
            defaultName={"Property Type"}
            options={typeOptions}
            hiddenDefaultName={false}
            onSelect={(val) => {
              handleDropdownChanged("property_type", val);
              fetchFeatures(val);
            }}
          />
          <Dropdown
            classes={"result-select"}
            defaultName={"Price"}
            options={priceOptions}
            hiddenDefaultName={false}
            onSelect={(e) => handleDropdownChanged("price_range", e)}
          />
          <Dropdown
            classes={"result-select"}
            defaultName={"No. of Rooms"}
            options={roomsOptions}
            hiddenDefaultName={false}
            onSelect={(e) => handleDropdownChanged("number_of_rooms", e)}
          />
        </div>
        <div className="d-flex-column">
          {loading && <p className="text-center">Loading...</p>}
          {error && <p className="text-center">Error: {error}</p>}
          {!loading && !error && (
            <>
              {featuresOptions && (
                <MultiSelect
                  options={featuresOptions}
                  selectedValues={preferencesData["features"]}
                  onChange={(e) => handleDropdownChanged("features", e)}
                />
              )}
              {subfeaturesOptions && (
                <MultiSelect
                  options={subfeaturesOptions}
                  selectedValues={preferencesData["sub_features"]}
                  onChange={(e) => handleDropdownChanged("sub_features", e)}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Button
        classes={"result-btn"}
        label="Refresh Properties"
        onClick={() => onSearchClicked(preferencesData)}
      />
    </>
  );
};

export default ResultSearch;
