import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Dropdown from "../components/Dropdown";

const options = [
  { value: "user1", label: "user 1" },
  { value: "user2", label: "user 2" },
];

function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState('');

  const handleUserChanged = (selectedOption) => {
    let _user = JSON.parse(JSON.stringify(user));
    _user = selectedOption;
    setUser(_user);
  };

  const handleNextClicked = () => {
    navigate(`/result?user=${user}`);
  };

  return (
    <div className="step1-wrap">
      <h2 className="p-40">Find Your Perfect Rental Property</h2>
      <p>Identify Yourself and Choose a City to get started!</p>
      <div className="w-360">
        <Dropdown
          defaultName={"User"}
          options={options}
          onSelect={handleUserChanged}
        />
      </div>
      <Button
        classes={"w-360"}
        label="Find Properties"
        disabled={!user}
        onClick={handleNextClicked}
      />
    </div>
  );
}

export default Home;
