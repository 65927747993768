import React from "react";

const MultiSelect = ({ options = [], selectedValues = [], onChange }) => {
  const handleOptionClick = (optionValue) => {
    const isSelected = selectedValues.includes(optionValue);
    if (isSelected) {
      onChange(selectedValues.filter((value) => value !== optionValue));
    } else {
      onChange([...selectedValues, optionValue]);
    }
  };

  return (
    <div className="multi-select">
      {options.map((option, index) => (
        <div
          key={index}
          className={`multi-select-item ${
            selectedValues.includes(option) && "active"
          }`}
          onClick={() => handleOptionClick(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default MultiSelect;
